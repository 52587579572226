<template>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
        right
        app
        :style="{ width: this.drawer ? '540px' : '0px', height: this.drawer ? '100%' : '0px'  }"
        :stateless="true"
        v-click-outside="onClickOutsideDrawer"
        class="elevation-0"
    >
        <v-container class="py-4 px-8 mb-8" style="position: relative;">
            <v-row class="d-flex align-center">
                <span v-if="loading">Carregando...</span>
                <span v-else class="mr-4">{{ `${comments.length} ${comments.length === 1 ? 'comentário' : 'comentários'}` }}</span>

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon left>mdi-sort-variant</v-icon>
                            Ordenar por
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item-group color="primary">
                            <v-list-item class="cursor-pointer">
                                <v-list-item-title @click="sortByAnswers()">Principais comentários</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="cursor-pointer">
                                <v-list-item-title @click="comments.reverse()">Mais recentes primeiro</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>

                <v-spacer></v-spacer>
                
                <v-btn icon small text @click="closeDrawer()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>

            <v-row class="d-flex align-center mt-4">
                <v-avatar v-if="getUser.photo" size="50" class="mr-4">
                    <v-img :src="getUser.photo"
                        :aspect-ratio="1/1"
                    />
                </v-avatar>
                <v-icon v-else x-large color="primary" class="mr-4">mdi-account-circle</v-icon>

                <v-text-field ref="newCommentInput"
                    :value="newComment"
                    @input="newComment = $event"
                    label="Adicionar um comentário"
                    class="text-wrap"
                />
            </v-row>
            
            <v-row class="d-flex justify-end" v-if="newComment.length > 0">
                <v-btn text color="primary" small class="mr-4" @click="newComment = '', selectedUsers = []">Cancelar</v-btn>
                <v-btn color="primary" small @click="pushNewComment()" :loading="commenting">Comentar</v-btn>
            </v-row>

            <v-expand-transition>
                <v-sheet v-show="fetchingUsers || users.length > 0"
                    style="position: absolute; top: 105px; left: 75px; z-index: 1000;"
                    elevation="2"
                >
                    <v-list v-if="!fetchingUsers">
                        <v-list-item-group v-for="user in users" :key="user.id_user"
                            color="primary" class="d-flex align-center"
                        >
                            <v-list-item class="cursor-pointer" @click="selectUser(user)">
                                <v-avatar v-if="user.photo" size="32" class="mr-2">
                                    <v-img :src="user.photo"
                                        :aspect-ratio="1/1"
                                    />
                                </v-avatar>
                                <v-icon v-else large color="primary" class="mr-2">mdi-account-circle</v-icon>
                                <v-list-item-title>{{ user.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>

                    <v-btn v-else x-large text color="primary" :loading="fetchingUsers"></v-btn>
                </v-sheet>
            </v-expand-transition>
            
            <template v-if="comments.length > 0">
                <div v-for="(comment) in comments" :key="comment.id">
                    <v-row class="d-flex align-start mt-8" >
                        <v-col cols="1" class="pa-0 mr-2">
                            <v-avatar v-if="comment.photo" size="40" class="mr-4">
                                <v-img :src="comment.photo"
                                    :aspect-ratio="1/1"
                                />
                            </v-avatar>
                            <v-icon v-else large color="primary" class="mr-4">mdi-account-circle</v-icon>
                        </v-col>
                        <v-col class="py-0 d-flex flex-column">
                            <div class="d-flex align-center">
                                <span class="text-subtitle-2 mr-2">{{ comment.name }}</span>
                                <span v-if="comment.updated_at" class="text-caption">{{ `Editado ${formatDate(comment.updated_at)}` }}</span>
                                <span v-else class="text-caption">{{ `Criado ${formatDate(comment.created_at)}`  }}</span>

                                <v-spacer></v-spacer>

                                <v-menu offset-y v-if="comment.id_user === getUser.id_user">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon x-small
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon small>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item-group color="primary">
                                            <v-list-item class="cursor-pointer">
                                                <v-list-item-title @click="comment.edit = true, backupComment = comment.description">Editar</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer">
                                                <v-list-item-title @click="deleteComment(comment)">Excluir</v-list-item-title>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                            </div>

                            <div v-if="comment.edit">
                                <v-text-field 
                                    v-model="comment.description"
                                    label="Editar comentário"
                                    class="text-wrap mt-2"
                                />
                                <div class="mb-4 d-flex justify-end" style="margin-top: -6px;">
                                    <v-btn color="primary" class="mr-4" text small @click="comment.edit = false, comment.description = backupComment">Cancelar</v-btn>
                                    <v-btn color="primary" small 
                                        @click="updateComment(comment)"
                                        :disabled="comment.description.length === 0"
                                        :loading="updatingComment"
                                    >
                                        Confirmar
                                    </v-btn>
                                </div>
                            </div>

                            <span v-else>{{ comment.description }}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pa-0 mr-2"></v-col>
                        <v-col class="pl-0 py-0 d-flex align-center">
                            <v-btn small text color="primary text-capitalize"
                                :disabled="comment.answers.length === 0"
                                @click="comment.showAnswers = !comment.showAnswers"
                            >
                                <v-icon left small color="primary" v-if="comment.answers.length > 0">
                                    {{ comment.showAnswers ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                </v-icon>
                                {{ setShowAnswersButtonText(comment.answers.length, comment.showAnswers) }}
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn small text @click="newAnswer = '', openNewAnswerField(comment)">Nova resposta</v-btn>
                        </v-col>
                    </v-row>

                    <template v-if="comment.showAnswers && comment.answers.length > 0">
                        <v-row v-for="answer in comment.answers" :key="answer.text" class="mt-6">
                            <v-col cols="1" class="pa-0 mr-2"></v-col>
                            <v-col class="d-flex align-center py-0">
                                <v-avatar v-if="answer.photo" size="32" class="mr-4">
                                    <v-img :src="answer.photo"
                                        :aspect-ratio="1/1"
                                    />
                                </v-avatar>
                                <v-icon v-else large color="primary" class="mr-4">mdi-account-circle</v-icon>
                                <div class="d-flex flex-column" style="width: 100%;">
                                    <div class="d-flex align-center">
                                        <span class="text-subtitle-2 mr-2">{{ answer.name }}</span>
                                        <span v-if="answer.updated_at" class="text-caption">{{ `Editado ${formatDate(answer.created_at)}` }}</span>
                                        <span v-else class="text-caption">{{ `Criado ${formatDate(answer.created_at)}`  }}</span>

                                        <v-spacer></v-spacer>

                                        <v-menu offset-y v-if="answer.id_user === getUser.id_user">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon small>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item-group color="primary">
                                                    <v-list-item class="cursor-pointer">
                                                        <v-list-item-title @click="answer.edit = true, backupAnswer = answer.description">Editar</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer">
                                                        <v-list-item-title @click="deleteAnswer(answer)">Excluir</v-list-item-title>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-menu>
                                    </div>

                                    <div v-if="answer.edit">
                                        <v-text-field 
                                            v-model="answer.description"
                                            label="Editar resposta"
                                            class="text-wrap mt-2"
                                        />
                                        <div class="mb-4 d-flex justify-end" style="margin-top: -6px;">
                                            <v-btn color="primary" class="mr-4" text small @click="answer.edit = false, answer.description = backupAnswer">Cancelar</v-btn>
                                            <v-btn color="primary" small 
                                                @click="updateAnswer(answer)"
                                                :disabled="answer.description.length === 0"
                                                :loading="updatingAnswer"
                                            >
                                                Confirmar
                                            </v-btn>
                                        </div>
                                    </div>
                                    <span v-else>{{ answer.description }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    
                    <template v-if="comment.newAnswer">
                        <v-row class="mt-4">
                            <v-col cols="1" class="pa-0 mr-2"></v-col>
                            <v-col class="d-flex align-center py-0">
                                <v-avatar v-if="getUser.photo" size="32" class="mr-4">
                                    <v-img :src="getUser.photo"
                                        :aspect-ratio="1/1"
                                    />
                                </v-avatar>
                                <v-icon v-else large color="primary" class="mr-4">mdi-account-circle</v-icon>
                                <v-text-field ref="newAnswerInput"
                                    class="text-wrap"
                                    v-model="newAnswer"
                                    label="Adicionar uma resposta"
                                    @keydown.enter="pushNewAnswer(comment)"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2 d-flex justify-end">
                            <v-btn color="primary" class="mr-4" text small @click="comment.newAnswer = false, newAnswer = ''">Cancelar</v-btn>
                            <v-btn color="primary" small
                                @click="pushNewAnswer(comment)"
                                :disabled="newAnswer.length === 0"
                                :loading="pushingAnswer"
                            >
                                Responder
                            </v-btn>
                        </v-row>
                    </template>
                </div>
            </template>

            <template v-else>
                <v-sheet class="d-flex justify-center align-center mt-12">
                    <v-btn text color="primary" :loading="loading"></v-btn>
                </v-sheet>
            </template>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import lodash from 'lodash'

export default {
    name: 'CommentsMenu',

    props: {
        drawer: { type: Boolean },
        parentPayload: { type: Object }
    },

    data() {
        return {
            commentsDataRows: null,
            comments: [],
            newComment: '',
            backupComment: '',
            newAnswer: '',
            backupAnswer: '',
            loading: false,
            commenting: false,
            updatingComment: false,
            pushingAnswer: false,
            updatingAnswer: false,

            users: [],
            selectedUsers: [],
            fetchingUsers: false,
            userChipMenu: false,
        }
    },

    computed: {
        ...mapGetters('auth', ['getUser']),

        answering() {
            return this.comments.find(c => c.newAnswer) 
        }
    },

    watch: {
        drawer: {
            immediate: true,
            handler(drawer) {
                if (drawer) {
                    this.getComments()
                }
            }
        },

        parentPayload: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getComments()
                }
            }
        },

        newComment(newComment) {
            let userName = ''
            if (newComment.includes('@')) {
                userName = newComment.split('@')[1]
                if (userName.length >= 3) {
                    this.getUsers(userName)
                }
            } else {
                this.users = []
            }
        }
    },

    mounted() {
        window.setInterval(() => {
            if (this.drawer && this.newComment === '' && this.newAnswer === '') {
                let isEditingComment = this.comments.find(c => c.edit === true)
                let isEditingAnswer = null
                let foundComment = this.comments.find(c => c.answers.length > 0)
                if (foundComment) {
                    isEditingAnswer = foundComment.answers.find(a => a.edit === true)
                }

                if (!isEditingComment && !isEditingAnswer) {
                    this.getComments()
                }
            }
        }, 15000)

        window.addEventListener('keydown', this.keyDownHandler)
    },

    destroyed () {
        window.removeEventListener('keydown', this.keyDownHandler)
    },

    methods: {
        selectUser(user) {
            this.selectedUsers.push(user)
            this.users = []
            let comment = this.newComment.split(' ')
            comment.map((c, index) => {
                if (c.includes('@')) {
                    comment[index] = `${user.name} `
                }
            })

            this.newComment = comment.join().replaceAll(',', ' ')
            this.$refs.newCommentInput.focus()
        },

        async getUsers(userName) {
            if (!this.fetchingUsers) {
                this.fetchingUsers = true
                this.users = []

                let payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "name",
                                operator: "CONTAINS",
                                value: userName
                            }
                        ]
                    }
                }
    
                try {
                    let res = await this.$http.post(this.$ipUser + 'user/list', { ...payload })
                    if (res) {
                        let users = res.data.rows

                        users.forEach(user => {
                            this.users.push(user)
                        })

                        this.fetchingUsers = false
                    }
                } catch (err) {
                    this.fetchingUsers = false
                    this.$fnError(err)
                }
            }
        },

        onClickOutsideDrawer(e) {
            if (e.srcElement.className === 'v-overlay__scrim') {
                this.closeDrawer()
            }
        },

        async getComments(current) {
            let selected_filters = JSON.stringify(this.parentPayload.id_event)

            const payload = {
                id_dynamic_report_slide: this.parentPayload.id_dynamic_report_slide,
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "selected_filters",
                            operator: "=",
                            value: selected_filters
                        }
                    ]
                }
            }

            try {
                let res = await this.$http.post(this.$ipComment + 'dynamic-report-comments/list', { ...payload })
                if (res) {
                    if (!lodash.isEqual(res.data.rows, this.commentsDataRows)) {
                        this.loading = true
                        this.commentsDataRows = res.data.rows
                        this.comments = []

                        res.data.rows.forEach(row => {
                            if (row.id_comments_parent) {
                                let foundComment = this.comments.find(comment => comment.id_comments === row.id_comments_parent)
                                if (foundComment) {
                                    foundComment.answers.push({ edit: false, ...row })
                                }
                            } else {
                                let showAnswers = false

                                if (current) {
                                    if (row.id_comments === current.id_comments || row.id_comments === current.id_comments_parent) {
                                        showAnswers = true
                                    }
                                }

                                this.comments.push({ edit: false, showAnswers, newAnswer: false, answers: [], ...row })
                            }
                        })
                    }

                    this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        async pushNewComment() {
            this.commenting = true
            this.loading = true

            let selected_filters = JSON.stringify(this.parentPayload.id_event)

            let payload = {
                selected_filters: selected_filters,
                id_dynamic_report_slide: this.parentPayload.id_dynamic_report_slide,
                description: this.newComment,
                id_comments_parent: null,
            }

            try {
                let res = await this.$http.post(this.$ipComment + 'dynamic-report-comments/create', { ...payload })
                if (res) {
                    this.getComments()
                    this.commenting = false
                }
            } catch (err) {
                this.commenting = false
                this.$fnError(err)
            }

            this.newComment = ''
        },

        openNewAnswerField(comment) {
            this.comments.forEach(comment => {
                comment.newAnswer = false
            })
            comment.newAnswer = true
        },

        async pushNewAnswer(comment) {
            this.pushingAnswer = true
            this.loading = true

            let selected_filters = JSON.stringify(this.parentPayload.id_event)

            let payload = {
                selected_filters: selected_filters,
                id_dynamic_report_slide: this.parentPayload.id_dynamic_report_slide,
                description: this.newAnswer,
                id_comments_parent: comment.id_comments,
            }

            try {
                let res = await this.$http.post(this.$ipComment + 'dynamic-report-comments/create', { ...payload })
                if (res) {
                    this.getComments(comment)
                    this.pushingAnswer = false
                }
            } catch (err) {
                this.pushingAnswer = false
                this.$fnError(err)
            }

            comment.newAnswer = false
            this.newAnswer = ''
        },

        async updateComment(comment) {
            this.updatingComment = true

            let payload = {
                description: comment.description,
                id_dynamic_report_comments: comment.id_dynamic_report_comments
            }

            try {
                let res = await this.$http.put(this.$ipComment + 'dynamic-report-comments/edit-id', { ...payload })
                if (res) {
                    this.getComments()
                    this.updatingComment = false
                }
            } catch (err) {
                this.updatingComment = false
                this.loading = false
                this.$fnError(err)
            }
        },

        async updateAnswer(answer) {
            this.updatingAnswer = true

            let payload = {
                description: answer.description,
                id_dynamic_report_comments: answer.id_dynamic_report_comments,
            }

            try {
                let res = await this.$http.put(this.$ipComment + 'dynamic-report-comments/edit-id', { ...payload })
                if (res) {
                    this.getComments(answer)
                    this.updatingAnswer = false
                }
            } catch (err) {
                this.updatingAnswer = false
                this.loading = false
                this.$fnError(err)
            }
        },

        async deleteComment(comment) {
            this.loading = true

            if (comment.answers.length > 0) {
                this.$toast.error('Não é possível deletar o comentário porque ele possui respostas')
            } else {
                try {
                    let res = await this.$http.delete(this.$ipComment + `dynamic-report-comments/delete/${comment.id_dynamic_report_comments}`, {})
                    if (res) {
                        this.getComments()
                    }
                } catch (err) {
                    this.loading = false
                    this.$fnError(err)
                }
            }
        },

        async deleteAnswer(answer) {
            this.loading = true

            try {
                let res = await this.$http.delete(this.$ipComment + `dynamic-report-comments/delete/${answer.id_dynamic_report_comments}`, {})
                if (res) {
                    this.getComments(answer)
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        closeDrawer() {
            this.$emit('closeDrawer')
        },

        keyDownHandler(event) {
            if (event.code === 'Escape' && !this.newComment.length && !this.answering) {
                this.closeDrawer()
            }

            if (event.code === 'Escape' && this.newComment.length && this.$refs.newCommentInput.isFocused) {
                this.newComment = ''
                this.$refs.newCommentInput.blur()
            }

            if (event.code === 'Escape' && this.answering) {
                this.answering.newAnswer = false
            }

            if (event.code === 'Enter' && this.newComment.length && this.$refs.newCommentInput.isFocused) {
                this.pushNewComment()
                this.$refs.newCommentInput.blur()
            }
        },

        formatDate(createdAt) {
            moment.locale('pt-br')
            return moment(createdAt).fromNow()
        },

        sortByAnswers() {
            this.comments.sort((a,b) => {
                return b.answers.length - a.answers.length
            }) 
        },

        setShowAnswersButtonText(length, showAnswers) {
            let text = null
            if (showAnswers) {
                if (length > 0) {
                    if (length === 1) {
                        text = 'Ocultar resposta'
                    } else {
                        text = `Ocultar ${length} respostas`
                    }
                } else {
                    text = '0 respostas'
                }
            } else {
                if (length > 0) {
                    if (length === 1) {
                        text = 'Ver resposta'
                    } else {
                        text = `Ver ${length} respostas`
                    }
                } else {
                    text = '0 respostas'
                }
            }

            return text
        }
    }
}
</script>

<style scoped>
.v-navigation-drawer {
    z-index: 1500 !important;
    box-shadow: none !important;
}

.cursor-pointer:hover {
    cursor: pointer;
}
</style>